import _ from 'lodash'
import languageMapping from '~/config/languageMapping';

export const mainAreaCode = '1'

// 获取排序后多语言
export const getSortByPriority = () => languageMapping.sort((a, b) => a.sortPirority - b.sortPirority);

export const defaultMapping = languageMapping.find(item => item.default === true)
const isEqualIgnoreCase = (str1, str2) => {
  return str1.localeCompare(str2, undefined, { sensitivity: 'accent' }) === 0
}
export const getGeeTestLocale = (i18nLanguage) => {
  const item = languageMapping.find(item =>
    _.some(item.localeCodes, localeCode => isEqualIgnoreCase(localeCode, i18nLanguage))
  )
  return item ? item.geeTestLocale : defaultMapping?.geeTestLocale ?? 'en'
}

export const getSupportLocaleByLanguageType = (langCode) => {
  const item = languageMapping.find(item =>
    _.some(item.localeCodes, localeCode => isEqualIgnoreCase(localeCode, langCode))
  )
  return item ? item.supportLocale : defaultMapping?.supportLocale ?? 'en-us'
}

export const getLangByLocaleCode = (localeCode) => {
  // return localeCode if its type is language already
  const validTypes = /^(en|zh-TW|zh-CN|ko|vi)$/
  if (validTypes.test(localeCode)) return localeCode

  const item = languageMapping.find(item =>
    _.some(item.localeCodes, localeCodeEle => isEqualIgnoreCase(localeCodeEle, localeCode))
  )
  return item ? item.language : defaultMapping?.language ?? 'en'
}

export const getBackendLocaleCodeByLocaleCode = (localeCode) => {
  // 后端接受的语言类型必须为底线而非连字符
  // Otherwise, transform into LocalTypes
  const item = languageMapping.find(item =>
    _.some(item.localeCodes, localeCodeItem => isEqualIgnoreCase(localeCodeItem, localeCode))
  )
  return item ? item.backendLocaleCode : defaultMapping?.backendLocaleCode ?? 'en_US'
}

export const getLangByBrowserLanguage = () => {
  const localeCode = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language
  return getLangByLocaleCode(localeCode)
}

export const getLangByUserCountry = (countryCode) => {
  const item = languageMapping.find(item =>
    _.some(item.countryCodes, countryCodeEle => isEqualIgnoreCase(countryCodeEle, countryCode))
  )
  return item ? item.language : defaultMapping?.language ?? 'en'
}

export const getLangCodeByLang = (language) => {
  const item = languageMapping.find(item => item.localeCodes.some(i => isEqualIgnoreCase(i, language)))
  return item ? item.langCode : defaultMapping?.langCode ?? 0
}

export const getLangCodeByLocaleCode = (localeCode) => {
  const item = languageMapping.find(item =>
    _.some(item.localeCodes, localeCodeItem => isEqualIgnoreCase(localeCodeItem, localeCode))
  )
  return item ? item.langCode : defaultMapping?.langCode ?? 0
}

// 获取当前语言-设置区号
export const getLanguageSetAreaCode = (language) => {
  const defaultLng = language || getLangByBrowserLanguage()
  const item = languageMapping.find(item => isEqualIgnoreCase(item.language, defaultLng))
  let areaCode = item ? item.areaCode : defaultMapping?.areaCode ?? '1'
  if (areaCode === '886') areaCode = '86'
  return areaCode
}

